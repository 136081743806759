import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'

import './styles.scss'
//Components
import SignupForm from '@components/form/Form.ContactUs.jsx'
// markup
const ContactUsPage = ({ data }) => {
  return (
    <Layout addedClass="page--contact-us">
      <DocumentHeadMeta title='Contact Us | ALL IN&reg; for Lupus Nephritis' description="If you're looking for additional information on lupus nephritis and want to learn more about ALL IN&reg;, get in touch with us. We'd love to hear from you." canonicalURL='https://www.allinforlupusnephritis.com/contact' />
      <SignupForm />
    </Layout>
  )
}

export default ContactUsPage